import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import Vue from 'vue'

export default function usePOReceivesWarehouseList() {
  const toast = useToast()
  const refPurchaseOrderReceiveWarehouseTable = ref(null)
  const tableColumns = [
    { key: 'ReceivingNumber', sortable: true },
    { key: 'TotalReceived', sortable: true },
    { key: 'CreatedBy', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'Action', sortable: true },
  ]
  const perPage = ref(10)
  const totalPurchaseOrder = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refPurchaseOrderReceiveWarehouseTable.value ? refPurchaseOrderReceiveWarehouseTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPurchaseOrder.value,
    }
  })

  const refetchData = () => {
    refPurchaseOrderReceiveWarehouseTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter, sortBy, isSortDirDesc], () => {
    refetchData()
  })

  const fetchPurchaseOrdersReceivesWarehouse = (ctx, callback) => {
    const to = perPage.value * (currentPage.value - 1)
    const userToken = Vue.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER_RECEIVES_WAREHOUSE}?limit=${perPage.value}&offset=${to}&search=${searchQuery.value}`, { headers })
      .then(response => {
        totalPurchaseOrder.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching data list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchPurchaseOrdersReceivesWarehouse,
    tableColumns,
    perPage,
    currentPage,
    totalPurchaseOrder,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPurchaseOrderReceiveWarehouseTable,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    // Extra Filters
    roleFilter,
    statusFilter,
  }
}
